
import { defineComponent, onMounted } from "vue";
import TableWidget9 from "@/components/widgets/tables/Widget9.vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
//import { MenuComponent } from "@/assets/ts/components";
import slaveTemplatesTable from "@/components/SlaveTemplatesTable.vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";

export default defineComponent({
  name: "administration",
  components: {
    slaveTemplatesTable,
    Dropdown1,
  },
  setup() {
    onMounted(() => {
      //setCurrentPageBreadcrumbs("Charts", ["Widgets"]);
      setCurrentPageTitle("Templates Administration");
      //MenuComponent.reinitialization();
    });
  },
});
