import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center py-1" }
const _hoisted_2 = { class: "me-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown1 = _resolveComponent("Dropdown1")!
  const _component_slaveTemplatesTable = _resolveComponent("slaveTemplatesTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Dropdown1)
      ])
    ]),
    _createVNode(_component_slaveTemplatesTable, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-8" })
  ], 64))
}