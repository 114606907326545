
import { defineComponent, onMounted, ref,computed } from "vue";
import ApiService from "@/core/services/ApiService";
//import  Master from "@/models/MasterModel"
import * as Mast from "@/models/MasterModel"
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";

export default defineComponent({
  name: "slaves-templates-table",
  components: {},
  props: {
    widgetClasses: String,
    //masterId: String,
  },
  setup(props) {
    
    const router = useRouter();
    const store = useStore();
    
    const slaves = ref<Mast.SlaveTemplate[]>([]as Mast.SlaveTemplate[]);
    onMounted(() => {
      getSlaveTemplates()

    })

    const getSlaveTemplates = () =>{
        const postData ={//todo geert  tijdelijke params
        "searchString": '',
        "parentIds": [],
        "pageSize": 10000,
        "pageNumber": ''
         }as any;
        ApiService.post("/template/slavetemplates",postData)
        .then(({ data }) => {
            console.log('getSlaveTemplates response data:');//todo geert
            console.log({...data});//todo geert
            slaves.value = data.results; 
        })
        .catch(({ response }) => {
          console.log("Fout getSlaveTemplates")
           
        });
    }

    const clickNewSlaveTemplate =() => {
          console.log("goNewSlaveTemplate");
          store.commit('setSlaveTemplateId',0) 
          router.push({ name: "editslavetemplate" });

    }

    const clickEditSlaveTemplate =(data) => {
      console.log("goEditSlaveTemplate");
       console.log(data)
          store.commit('setSlaveTemplateId',data.id) 
          router.push({ name: "editslavetemplate"});
    }

    const clickDeleteSlaveTemplate =(data:Mast.SlaveTemplate) => {
      Swal.fire({

          title: 'Are you sure to delete slave-template '+data.name +' ?',
          showCancelButton: true,
          icon:"warning",  
          buttonsStyling: true,
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          
        }).then((result) => { 
          if(result.isConfirmed){
              deleteSlaveTemplate(data)
          } 
        });
    }

    const deleteSlaveTemplate =(data) => {
      console.log("deleteSlave:");
      console.log(data)
   
        ApiService.post("/template/slavetemplatedelete",data as any)
        .then(({ data }) => {
            console.log('delete slavetemplate response data:');//todo geert
            console.log({...data});//todo geert
            getSlaveTemplates();
        })
        .catch(({ response }) => {
          console.log("Fout slaveTemplatedelete")
           
        });

    }


    return {
      slaves,
      clickNewSlaveTemplate,
      clickEditSlaveTemplate,
      clickDeleteSlaveTemplate
    };
  },
});
